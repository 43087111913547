<template>
    <Header/>
        <Banner :name="'Events/'+details.title" 
            :message="details.type && details.type.toLowerCase() == 'sponsorship' ? 'Sponsor a student' 
            : 'Signup for our event'" />
        <div class="section section-padding mt-n1">
            <div class="container">
                <!-- Section Title Start -->
                <div class="section-title shape-03 text-center">
                    <h2 class="main-title header-text"><span>{{details.title}}  Details</span></h2>
                </div>
                <!-- Section Title End -->

                <!-- Blog Wrapper Start -->
                <div class="blog-wrapper">
                    <div class="row">
                      <div class="col-lg-6 ">
                          <div class="card">
                            <div class="card-body">
                                <img :src="details.cover_photo" class="img-fluid" />
                            </div>
                          </div>
                          <div class="my-4" v-html="details.body">
                          </div>
                      </div>
                      <div class="col-lg-6 ">
                        <h3 class="title" v-if="details.type && details.type.toLowerCase() == 'sponsorship'">Sponsorship <span>Form</span></h3>
                        <h3 class="title" v-else>Register <span>Now</span></h3>
                        <!-- Start Scholarship  -->
                        <form 
                            v-if="details.type.toLowerCase() == 'ishkscholarship' || 
                            details.type.toLowerCase() == 'onlineclass' || 
                            details.type.toLowerCase() == 'bacwwanscholarship' ||
                            details.type.toLowerCase() == 'online'"
                        > 
                            <div class="row">
                                <div class="single-form col-lg-6">
                                    <input type="text" v-model="signupData.name"  placeholder="Full Name" required>
                                </div>                                
                                <div class="single-form col-lg-6">
                                    <input type="email" v-model="signupData.email"  placeholder="Email" required>
                                </div>                                
                                <div class="single-form col-lg-6">
                                    <input type="tel" v-model="signupData.phone_number"  placeholder="Phone Number" required >
                                </div>                                
                                <div class="single-form col-lg-6">
                                    <input type="tel" v-model="signupData.alt_phone_number"  placeholder="WhatsApp Number" required>
                                </div>                                
                                <div class="single-form col-lg-6">
                                    <input type="number" min="16" v-model="signupData.age"  placeholder="Age" required>
                                </div>                               
                                <div class="single-select2 col-lg-6">
                                    <select class="nice-select "  name="gender" @change="selectInput($event)">
                                        <option value="">--Select Gender--</option>
                                        <option value="Female">Female</option>
                                        <option value="Male">Male</option>
                                    </select>
                                </div> 
                                <div class="single-form col-lg-12">
                                    <input type="text" @focus="setDateField($event)" v-model="signupData.dob"  placeholder="Date of Birth" required>
                                </div>                                                                                               
                                <div class="single-select2 col-lg-12">
                                    <select class="nice-select "  name="state" @change="selectInput($event)">
                                        <option value="">--Select State--</option>
                                        <option value="Abia">Abia</option>
                                        <option value="Adamawa">Adamawa</option>
                                        <option value="AkwaIbom">AkwaIbom</option>
                                        <option value="Anambra">Anambra</option>
                                        <option value="Bauchi">Bauchi</option>
                                        <option value="Bayelsa">Bayelsa</option>
                                        <option value="Benue">Benue</option>
                                        <option value="Borno">Borno</option>
                                        <option value="Cross River">Cross River</option>
                                        <option value="Delta">Delta</option>
                                        <option value="Ebonyi">Ebonyi</option>
                                        <option value="Edo">Edo</option>
                                        <option value="Ekiti">Ekiti</option>
                                        <option value="Enugu">Enugu</option>
                                        <option value="FCT">FCT</option>
                                        <option value="Gombe">Gombe</option>
                                        <option value="Imo">Imo</option>
                                        <option value="Jigawa">Jigawa</option>
                                        <option value="Kaduna">Kaduna</option>
                                        <option value="Kano">Kano</option>
                                        <option value="Katsina">Katsina</option>
                                        <option value="Kebbi">Kebbi</option>
                                        <option value="Kogi">Kogi</option>
                                        <option value="Kwara">Kwara</option>
                                        <option value="Lagos">Lagos</option>
                                        <option value="Nasarawa">Nasarawa</option>
                                        <option value="Niger">Niger</option>
                                        <option value="Ogun">Ogun</option>
                                        <option value="Ondo">Ondo</option>
                                        <option value="Osun">Osun</option>
                                        <option value="Oyo">Oyo</option>
                                        <option value="Plateau">Plateau</option>
                                        <option value="Rivers">Rivers</option>
                                        <option value="Sokoto">Sokoto</option>
                                        <option value="Taraba">Taraba</option>
                                        <option value="Yobe">Yobe</option>
                                        <option value="Zamfara">Zamafara</option>
                                    </select>
                                </div>       
                                <div class="single-select2 col-lg-12">
                                    <select class="nice-select "  name="lga" @change="selectInput($event)">
                                        <option value="">--Select LGA--</option>
                                        <option v-for="lga in localGovernments" :key="lga">
                                            {{lga}}
                                        </option>
                                    </select>
                                </div>                                                            
                                <div class="single-select2 col-lg-12">
                                    <select class="nice-select "  name="employment_status" @change="selectInput($event)">
                                        <option value="">--Select Employment Status--</option>
                                        <option value="Employed">Employed</option>
                                        <option value="Unemployed">Unemployed</option>
                                        <option value="Self Employed">Self Employed</option>
                                    </select>
                                </div>                                  
                                <div class="single-select2 col-lg-12">
                                    <label>Trade you are interested to learn</label>
                                    <select 
                                        class="nice-select " 
                                        name="trade_of_interest" 
                                        @change="selectInput($event)"
                                        v-if="details.type.toLowerCase() === 'ishkscholarship'"
                                        
                                    >
                                        <option value="">--Select one--</option>
                                        <!-- <option value="Capentry">Capentry & Joinery/Funriture making</option> -->
                                        <option value="Plumbing/Pipe Fittings">Plumbing & Pipe Fittings </option>
                                        <option value="Tiling/Masonry">Tiling/Masonry</option>
                                    </select>
                                    <select 
                                        class="nice-select " 
                                        name="trade_of_interest" 
                                        @change="selectInput($event)"
                                        v-else
                                    >
                                        <option value="">--Select one--</option>
                                        <option value="Capentry">Capentry & Funriture Making</option>
                                        <option value="Plumbing/Pipe Fittings">Plumbing & Pipe Fittings </option>
                                        <option value="Tiling">Tiling</option>                                       
                                        <option value="Masonry">Masonry</option>  
                                        <option value="Electrical Installation">Electrical Installation</option>
                                    </select>                                   
                                </div>     
                                <div class="single-select2 col-lg-12" 
                                    v-if="details.type.toLowerCase() === 'ishkscholarship' || 
                                    details.type.toLowerCase() === 'bacwwanscholarship'"
                                >
                                    <label>Are you looking to get a long term employment?</label>
                                    <select class="nice-select " name="long_term_employment_interest" @change="selectInput($event)">
                                        <option value="" selected>--Select one--</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>                                                                                                                          
                                <!-- <div class="single-form col-lg-12" v-if="details.type.toLowerCase() != 'online'">
                                    <textarea  v-model="signupData.reason_for_interes" placeholder="Why do you think you should be offered this scholarship?" ></textarea>
                                </div>  -->
                                <div class="single-form col-lg-12" v-if="details.type.toLowerCase() != 'onlineclass'">
                                    <textarea  v-model="signupData.reason_for_interest" placeholder="In few sentences, tell us why you are interested in learning the trade" ></textarea>
                                </div>                                                                     
                                <div class="single-form col-lg-12">
                                    <!-- <label for="address">Address</label> -->
                                    <textarea v-model="signupData.address" placeholder="Your Address" ></textarea>
                                </div>   

                                <div class="single-select2 col-lg-12">
                                    <label>How did you know about this program?</label>
                                    <select class="nice-select " name="about_us" @change="selectInput($event)">
                                        <option value="" selected>--Select one--</option>
                                        <option value="Facebook">Facebook</option>
                                        <option value="Instagram">Instagram</option>
                                        <option value="LinkedIn">LinkedIn</option>
                                        <option value="Google Search">Google Search</option>
                                        <option value="Whatsapp">Whatsapp</option>
                                        <option value="Publication">Publication</option>
                                        <option value="Friend">Friend</option>
                                    </select>
                                </div>                                

                                <div class="single-form">
                                    <button 
                                        type="button" 
                                        class="btn btn-primary btn-hover-dark w-100"
                                        @click="signup"
                                    >
                                        <span v-if="loading">Please wait...</span>
                                        <span v-else>Submit</span> 
                                    </button>
                                </div>                                                                                                                                                          
                            </div>
                        </form> 
                        <!-- Start Scholarship -->
                        <!-- Start IBOR  -->
                        <form v-else-if="details.type.toLowerCase() == 'iborscholarship' || details.type.toLowerCase() == 'ibor'">
                            <div class="row">
                                <div class="single-form col-lg-6">
                                    <input type="text" v-model="signupData.name"  placeholder="Full Name" required>
                                </div>                                
                                <div class="single-form col-lg-6">
                                    <input type="email" v-model="signupData.email"  placeholder="Email" required>
                                </div>                                
                                <div class="single-form col-lg-6">
                                    <input type="tel" v-model="signupData.phone_number"  placeholder="Phone Number" required >
                                </div>                                
                                <div class="single-form col-lg-6">
                                    <input type="tel" v-model="signupData.alt_phone_number"  placeholder="WhatsApp Number" required>
                                </div>                                
                                <div class="single-form col-lg-6">
                                    <input type="number" min="16" v-model="signupData.age"  placeholder="Age" required>
                                </div>                               
                                <div class="single-select2 col-lg-6">
                                    <select class="nice-select "  name="gender" @change="selectInput($event)">
                                        <option value="">--Select Gender--</option>
                                        <option value="Female">Female</option>
                                        <option value="Male">Male</option>
                                    </select>
                                </div> 
                                <div class="single-form col-lg-12">
                                    <input type="text" @focus="setDateField($event)" v-model="signupData.dob"  placeholder="Date of Birth" required>
                                </div>                                                                                               
                                <div class="single-select2 col-lg-12">
                                    <select class="nice-select "  name="state" @change="selectInput($event)">
                                        <option value="">--Select State--</option>
                                        <option value="Abia">Abia</option>
                                        <option value="Adamawa">Adamawa</option>
                                        <option value="AkwaIbom">AkwaIbom</option>
                                        <option value="Anambra">Anambra</option>
                                        <option value="Bauchi">Bauchi</option>
                                        <option value="Bayelsa">Bayelsa</option>
                                        <option value="Benue">Benue</option>
                                        <option value="Borno">Borno</option>
                                        <option value="Cross River">Cross River</option>
                                        <option value="Delta">Delta</option>
                                        <option value="Ebonyi">Ebonyi</option>
                                        <option value="Edo">Edo</option>
                                        <option value="Ekiti">Ekiti</option>
                                        <option value="Enugu">Enugu</option>
                                        <option value="FCT">FCT</option>
                                        <option value="Gombe">Gombe</option>
                                        <option value="Imo">Imo</option>
                                        <option value="Jigawa">Jigawa</option>
                                        <option value="Kaduna">Kaduna</option>
                                        <option value="Kano">Kano</option>
                                        <option value="Katsina">Katsina</option>
                                        <option value="Kebbi">Kebbi</option>
                                        <option value="Kogi">Kogi</option>
                                        <option value="Kwara">Kwara</option>
                                        <option value="Lagos">Lagos</option>
                                        <option value="Nasarawa">Nasarawa</option>
                                        <option value="Niger">Niger</option>
                                        <option value="Ogun">Ogun</option>
                                        <option value="Ondo">Ondo</option>
                                        <option value="Osun">Osun</option>
                                        <option value="Oyo">Oyo</option>
                                        <option value="Plateau">Plateau</option>
                                        <option value="Rivers">Rivers</option>
                                        <option value="Sokoto">Sokoto</option>
                                        <option value="Taraba">Taraba</option>
                                        <option value="Yobe">Yobe</option>
                                        <option value="Zamfara">Zamafara</option>
                                    </select>
                                </div>       
                                <div class="single-select2 col-lg-12">
                                    <select class="nice-select "  name="lga" @change="selectInput($event)">
                                        <option value="">--Select LGA--</option>
                                        <option v-for="lga in localGovernments" :key="lga">
                                            {{lga}}
                                        </option>
                                    </select>
                                </div>                                                            
                                <div class="single-select2 col-lg-12">
                                    <select class="nice-select "  name="employment_status" @change="selectInput($event)">
                                        <option value="">--Select Employment Status--</option>
                                        <option value="Employed">Employed</option>
                                        <option value="Unemployed">Unemployed</option>
                                        <option value="Self Employed">Self Employed</option>
                                    </select>
                                </div>                                  
                                <div class="single-select2 col-lg-12">
                                    <label>Trade you are interested to learn</label>
                                    <select 
                                        class="nice-select " 
                                        name="trade_of_interest" 
                                        @change="selectInput($event)"                                        
                                    >
                                        <option value="">--Select one--</option>
                                        <option value="Carpentry">Carpentry</option>
                                        <option value="Interior Design">Integrior Design </option>
                                        <option value="Solar Installation">Solar Installation</option>
                                    </select>                                  
                                </div>                                                                                                                             
                                <div class="single-form col-lg-12">
                                    <textarea v-model="signupData.reason_for_interes" placeholder="Why do you think you should be offered this scholarship?" ></textarea>
                                </div>  
                                <div class="single-select2 col-lg-12">
                                    <label>Are you looking to get a long term employment?</label>
                                    <select class="nice-select " name="long_term_employment_interest" @change="selectInput($event)">
                                        <option value="" selected>--Select one--</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>    
                                <div class="single-form col-lg-12">
                                    <textarea v-model="signupData.address" placeholder="Address" ></textarea>
                                </div>   

                                <div class="single-select2 col-lg-12">
                                    <label>How did you know about this program?</label>
                                    <select class="nice-select " name="about_us" @change="selectInput($event)">
                                        <option value="" selected>--Select one--</option>
                                        <option value="Facebook">Facebook</option>
                                        <option value="Instagram">Instagram</option>
                                        <option value="LinkedIn">LinkedIn</option>
                                        <option value="Google Search">Google Search</option>
                                        <option value="Whatsapp">Whatsapp</option>
                                        <option value="Publication">Publication</option>
                                        <option value="Friend">Friend</option>
                                    </select>
                                </div>                                

                                <div class="single-form">
                                    <button 
                                        type="button" 
                                        class="btn btn-primary btn-hover-dark w-100"
                                        @click="signup"
                                    >
                                        <span v-if="loading">Please wait...</span>
                                        <span v-else>Submit</span> 
                                    </button>
                                </div>                                                                                                                                                          
                            </div>
                        </form> 
                        <!-- End IBOR -->

                        <!-- Start construction -->
                        <form v-else-if="details.type.toLowerCase() == 'interior' || details.type.toLowerCase() == 'bld'">
                            <div class="row">
                                <div class="single-form col-lg-6">
                                    <input type="text" v-model="signupData.name"  placeholder="Full Name" required>
                                </div>                                
                                <div class="single-form col-lg-6">
                                    <input type="email" v-model="signupData.email"  placeholder="Email" required>
                                </div>                                
                                <div class="single-form col-lg-6">
                                    <input type="tel" v-model="signupData.phone_number"  placeholder="Phone Number" required @change="confirmPhone">
                                </div>                                
                                <div class="single-form col-lg-6">
                                    <input type="tel" v-model="signupData.alt_phone_number"  placeholder="WhatsApp Number" required @change="confirmPhone">
                                </div>                                
                                <div class="single-form col-lg-6">
                                    <input type="number" min="16" v-model="signupData.age"  placeholder="Age" required>
                                </div>                               
                                <div class="single-select2 col-lg-6">
                                    <select class="nice-select "  name="gender" @change="selectInput($event)">
                                        <option value="">--Select Gender--</option>
                                        <option value="Female">Female</option>
                                        <option value="Male">Male</option>
                                    </select>
                                </div>  
                                <div class="single-form col-lg-12">
                                    <input type="text" @focus="setDateField($event)" v-model="signupData.dob"  placeholder="Date of Birth" required>
                                </div>                                    
                                <div class="single-select2 col-lg-12">
                                    <select class="nice-select "  name="employment_status" @change="selectInput($event)">
                                        <option value="">--Select Employment Status--</option>
                                        <option value="Employed">Employed</option>
                                        <option value="Unemployed">Unemployed</option>
                                        <option value="Self Employed">Self Employed</option>
                                    </select>
                                </div>                                                                                            
                                <div class="single-select2 col-lg-12">
                                    <select class="nice-select "  name="state" @change="selectInput($event)">
                                        <option value="">--Select State--</option>
                                        <option value="Abia">Abia</option>
                                        <option value="Adamawa">Adamawa</option>
                                        <option value="AkwaIbom">AkwaIbom</option>
                                        <option value="Anambra">Anambra</option>
                                        <option value="Bauchi">Bauchi</option>
                                        <option value="Bayelsa">Bayelsa</option>
                                        <option value="Benue">Benue</option>
                                        <option value="Borno">Borno</option>
                                        <option value="Cross River">Cross River</option>
                                        <option value="Delta">Delta</option>
                                        <option value="Ebonyi">Ebonyi</option>
                                        <option value="Edo">Edo</option>
                                        <option value="Ekiti">Ekiti</option>
                                        <option value="Enugu">Enugu</option>
                                        <option value="FCT">FCT</option>
                                        <option value="Gombe">Gombe</option>
                                        <option value="Imo">Imo</option>
                                        <option value="Jigawa">Jigawa</option>
                                        <option value="Kaduna">Kaduna</option>
                                        <option value="Kano">Kano</option>
                                        <option value="Katsina">Katsina</option>
                                        <option value="Kebbi">Kebbi</option>
                                        <option value="Kogi">Kogi</option>
                                        <option value="Kwara">Kwara</option>
                                        <option value="Lagos">Lagos</option>
                                        <option value="Nasarawa">Nasarawa</option>
                                        <option value="Niger">Niger</option>
                                        <option value="Ogun">Ogun</option>
                                        <option value="Ondo">Ondo</option>
                                        <option value="Osun">Osun</option>
                                        <option value="Oyo">Oyo</option>
                                        <option value="Plateau">Plateau</option>
                                        <option value="Rivers">Rivers</option>
                                        <option value="Sokoto">Sokoto</option>
                                        <option value="Taraba">Taraba</option>
                                        <option value="Yobe">Yobe</option>
                                        <option value="Zamfara">Zamafara</option>
                                    </select>
                                </div>                                  
                                <div class="single-select2 col-lg-12">
                                    <select class="nice-select "  name="lga" @change="selectInput($event)">
                                        <option value="">--Select LGA--</option>
                                        <option v-for="lga in localGovernments" :key="lga">
                                            {{lga}}
                                        </option>
                                    </select>
                                </div>  

                                <div class="single-form col-lg-12">
                                    <textarea v-model="signupData.address" placeholder="Address" ></textarea>
                                </div>                                  

                                <div class="single-select2 col-lg-12">
                                    <label>How did you know about this program?</label>
                                    <select class="nice-select " name="about_us" @change="selectInput($event)">
                                        <option value="" selected>--Select one--</option>
                                        <option value="Facebook">Facebook</option>
                                        <option value="Instagram">Instagram</option>
                                        <option value="LinkedIn">LinkedIn</option>
                                        <option value="Google Search">Google Search</option>
                                        <option value="Whatsapp">Whatsapp</option>
                                        <option value="Publication">Publication</option>
                                        <option value="Friend">Friend</option>
                                    </select>
                                </div> 
                                <div class="single-form">
                                    <button 
                                        type="button" 
                                        class="btn btn-primary btn-hover-dark w-100"
                                        @click="signup"
                                    >
                                        <span v-if="loading">Please wait...</span>
                                        <span v-else>Submit</span> 
                                    </button>
                                </div>                                                                                                                                                          
                            </div>
                        </form> 
                        <!-- end Core Course  -->

                        <!-- Start construction -->
                        <form 
                            v-else-if="details.type.toLowerCase() == 'freeday' || 
                                details.type.toLowerCase() == 'weekend' || 
                                details.type.toLowerCase() == 'course' ||
                                details.type.toLowerCase() == 'shortcourse'"
                            >
                            <div class="row">
                                <div class="single-form col-lg-6">
                                    <input type="text" v-model="signupData.name"  placeholder="Full Name" required>
                                </div>                                
                                <div class="single-form col-lg-6">
                                    <input type="email" v-model="signupData.email"  placeholder="Email" required>
                                </div>                                
                                <div class="single-form col-lg-6">
                                    <input type="tel" v-model="signupData.phone_number"  placeholder="Phone Number" required>
                                </div>                                
                                <div class="single-form col-lg-6">
                                    <input type="tel" v-model="signupData.alt_phone_number"  placeholder="Whatsapp Number" required>
                                </div>                                
                                <div class="single-form col-lg-6">
                                    <input type="number" min="16" v-model="signupData.age"  placeholder="Age" required>
                                </div>                                
                                <div class="single-select2 col-lg-6">
                                    <select class="nice-select "  name="gender" @change="selectInput($event)">
                                        <option value="">--Select Gender--</option>
                                        <option value="Female">Female</option>
                                        <option value="Male">Male</option>
                                    </select>
                                </div>  
                                <div class="single-form col-lg-12">
                                    <input type="text" @focus="setDateField($event)" v-model="signupData.dob"  placeholder="Date of Birth" required>
                                </div>                                
                                <div class="single-select2 col-lg-12">
                                    <select class="nice-select "  name="employment_status" @change="selectInput($event)">
                                        <option value="">--Select Employment Status--</option>
                                        <option value="Employed">Employed</option>
                                        <option value="Unemployed">Unemployed</option>
                                        <option value="Self Employed">Self Employed</option>
                                    </select>
                                </div>
                                <div class="single-select2 col-lg-12" v-if="details.type.toLowerCase() == 'freeday'">
                                    <select class="nice-select "  name="availablity">
                                        <option value="">-- Will you be available for the training? --</option>
                                        <option value="YES">YES</option>
                                        <option value="NO">NO</option>
                                    </select>
                                </div>                                                                                              
                                <div class="single-select2 col-lg-12">
                                    <select class="nice-select "  name="state" @change="selectInput($event)">
                                        <option value="">--Select State--</option>
                                        <option value="Abia">Abia</option>
                                        <option value="Adamawa">Adamawa</option>
                                        <option value="AkwaIbom">AkwaIbom</option>
                                        <option value="Anambra">Anambra</option>
                                        <option value="Bauchi">Bauchi</option>
                                        <option value="Bayelsa">Bayelsa</option>
                                        <option value="Benue">Benue</option>
                                        <option value="Borno">Borno</option>
                                        <option value="Cross River">Cross River</option>
                                        <option value="Delta">Delta</option>
                                        <option value="Ebonyi">Ebonyi</option>
                                        <option value="Edo">Edo</option>
                                        <option value="Ekiti">Ekiti</option>
                                        <option value="Enugu">Enugu</option>
                                        <option value="FCT">FCT</option>
                                        <option value="Gombe">Gombe</option>
                                        <option value="Imo">Imo</option>
                                        <option value="Jigawa">Jigawa</option>
                                        <option value="Kaduna">Kaduna</option>
                                        <option value="Kano">Kano</option>
                                        <option value="Katsina">Katsina</option>
                                        <option value="Kebbi">Kebbi</option>
                                        <option value="Kogi">Kogi</option>
                                        <option value="Kwara">Kwara</option>
                                        <option value="Lagos">Lagos</option>
                                        <option value="Nasarawa">Nasarawa</option>
                                        <option value="Niger">Niger</option>
                                        <option value="Ogun">Ogun</option>
                                        <option value="Ondo">Ondo</option>
                                        <option value="Osun">Osun</option>
                                        <option value="Oyo">Oyo</option>
                                        <option value="Plateau">Plateau</option>
                                        <option value="Rivers">Rivers</option>
                                        <option value="Sokoto">Sokoto</option>
                                        <option value="Taraba">Taraba</option>
                                        <option value="Yobe">Yobe</option>
                                        <option value="Zamfara">Zamafara</option>

                                    </select>
                                </div>                                  
                                <div class="single-select2 col-lg-12">
                                    <select class="nice-select "  name="lga" @change="selectInput($event)">
                                        <option value="">--Select LGA--</option>
                                        <option v-for="lga in localGovernments" :key="lga" :value="lga">
                                            {{lga}}
                                        </option>
                                    </select>
                                </div>  

                                <div class="single-form col-lg-12">
                                    <textarea v-model="signupData.address" placeholder="Address" ></textarea>
                                </div>                                
                                <div class="single-select2 col-lg-12">
                                    <select class="nice-select "  name="qualification" @change="selectInput($event)">
                                        <option value="">--Select Educational Qualification--</option>
                                        <option value="SSCE">SSCE</option>
                                        <option value="OND">OND</option>
                                        <option value="NCE">NCE</option>
                                        <option value="HND">HND</option>
                                        <option value="B.Sc">B.Sc</option>
                                        <option value="M.Sc">M.Sc</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </div>  
                                <div class="single-select2 col-lg-12" 
                                    v-if="details.type.toLowerCase() == 'course' || 
                                    details.type.toLowerCase() == 'shortcourse' ||
                                    details.type.toLowerCase() == 'weekend'"
                                >
                                    <label>Trade interested to learn</label>
                                    <select class="nice-select " name="trade_of_interest" @change="selectInput($event)">
                                        <option value="">--Select one--</option>
                                        <option value="Carpentry">Carpentry</option>
                                        <option value="Plumbing">Plumbing</option>
                                        <option value="Tiling">Tiling</option>
                                        <option value="Masonry">Masonry</option>
                                        <option value="Electrical Installation">Electrical Installation</option>
                                        <option value="Interior Design"> Interior Design</option>
                                        <option value="Welding and fabrication"> Welding and fabrication</option>
                                        <option value="Facility Maintenance"> Facility Maintenance </option>
                                        <option value="Solar Design and Installation"> Solar Design and Installation</option>
                                        <option value="Painting"> Painting </option>
                                        <option value="HVAC"> HVAC - Heat Ventilation and Air Conditioning</option>
                                        <option value="MEP"> Mechanical Electrical and Plumbing (MEP)</option>
                                    </select>
                                </div> 
                                <div class="single-select2 col-lg-12" v-if="details.type.toLowerCase() == 'course' || details.type.toLowerCase() == 'shortcourse'">
                                    <label>Select a Program</label>
                                    <select class="nice-select " name="program" @change="selectInput($event)">
                                        <option value="">--Select one--</option>
                                        <option value="1 Month Intensive">1 Month Intensive</option>
                                        <option value="3 Months Standard">3 Months Standard</option>
                                    </select>
                                </div> 
                                <div class="single-select2 col-lg-12" v-if="details.type.toLowerCase() === 'course'">
                                    <label>Are you looking to get a long term employment?</label>
                                    <select class="nice-select " name="long_term_employment_interest" @change="selectInput($event)">
                                        <option value="" selected>--Select one--</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>   
                                <div class="single-form col-lg-12" v-if="details.type.toLowerCase() === 'course' || details.type.toLowerCase() === 'shortcourse' || details.type.toLowerCase() === 'weekend'">
                                    <textarea  v-model="signupData.reason_for_interest" placeholder="In few sentences, tell us why you are interested in learning the trade" ></textarea>
                                </div>                                                                   
                                <div class="single-select2 col-lg-12">
                                    <label>How did you know about this program?</label>
                                    <select class="nice-select " name="about_us" @change="selectInput($event)">
                                        <option value="" selected>--Select one--</option>
                                        <option value="Facebook">Facebook</option>
                                        <option value="Instagram">Instagram</option>
                                        <option value="LinkedIn">LinkedIn</option>
                                        <option value="Google Search">Google Search</option>
                                        <option value="Whatsapp">Whatsapp</option>
                                        <option value="Publication">Publication</option>
                                        <option value="Friend">Friend</option>
                                    </select>
                                </div> 
                                <div class="single-form">
                                    <button 
                                        type="button" 
                                        class="btn btn-primary btn-hover-dark w-100"
                                        @click="signup"
                                    >
                                        <span v-if="loading">Please wait...</span>
                                        <span v-else>Submit</span> 
                                    </button>
                                </div>                                                                                                                                                          
                            </div>
                        </form> 
                        <!-- end Core Course  -->

                        <!-- Sponsorship -->
                        <form v-else-if="details.type && details.type.toLowerCase() == 'sponsorship'">
                            <div class="row">
                                <div class="single-form col-lg-6">
                                    <input type="text" v-model="signupData.name"  placeholder="Organisation Name" required>
                                </div>                                
                                <div class="single-form col-lg-6">
                                    <input type="email" v-model="signupData.email"  placeholder="Organisation Email" required>
                                </div>         
                                <div class="single-form col-lg-6">
                                    <input name="org_address" placeholder="Organisation Address" id="org_address" />
                                </div>                         
                                <div class="single-form col-lg-6">
                                    <input type="text" v-model="signupData.org_contact_name"  placeholder="Contact Person's Name" required>
                                </div>                                
                                <div class="single-form col-lg-6">
                                    <input type="email" v-model="signupData.org_contact_email"  placeholder="Contact Person's Email" required>
                                </div>                                
                                <div class="single-form col-lg-6">
                                    <input type="tel" pattern="[0][0-9]{10}" v-model="signupData.org_contact_phone"  placeholder="Contact Phone Number" required @change="confirmPhone">
                                </div>                                
                                <div class="single-form col-lg-6">
                                    <input type="text" v-model="signupData.org_contact_role"  placeholder="Contact Person's Role" required>
                                </div>                                
                                <div class="single-form col-lg-6">
                                    <input type="number" min="1" v-model="signupData.number_of_students"  placeholder="How many students will you like to sponsor?" required>
                                    <small class="text-primary">How many students will you like to sponsor?</small>
                                </div>                                
                                <div class="single-form">
                                    <button 
                                        type="button" 
                                        class="btn btn-primary btn-hover-dark w-100"
                                        @click="signup"
                                    >
                                        <span v-if="loading">Please wait...</span>
                                        <span v-else>Submit</span> 
                                    </button>
                                </div>                                                                                                                                                          
                            </div>
                        </form> 
                    <!-- /Sponsorship -->

                        <!-- Start core course -->
                        <form v-else-if="details.type && details.type.toLowerCase() == 'course' || details.type && details.type.toLowerCase() == 'shortcourse'">
                            <div class="row">
                                <div class="single-form col-lg-6">
                                    <input type="text" v-model="signupData.name"  placeholder="Full Name" required>
                                </div>                                
                                <div class="single-form col-lg-6">
                                    <input type="email" v-model="signupData.email"  placeholder="Email" required>
                                </div>                                
                                <div class="single-form col-lg-6">
                                    <input type="tel" v-model="signupData.phone_number"  placeholder="Phone Number" required @change="confirmPhone">
                                </div>                                
                                <div class="single-form col-lg-6">
                                    <input type="tel" v-model="signupData.alt_phone_number"  placeholder="Whatsapp Number" required @change="confirmPhone">
                                </div>                                
                                <div class="single-form col-lg-6">
                                    <input type="number" min="16" v-model="signupData.age"  placeholder="Age" required>
                                </div>                                
                                <div class="single-select2 col-lg-6">
                                    <select class="nice-select "  name="gender" @change="selectInput($event)">
                                        <option value="">--Select Gender--</option>
                                        <option value="Female">Female</option>
                                        <option value="Male">Male</option>
                                    </select>
                                </div>  
                                <div class="single-form col-lg-12">
                                    <input type="text" @focus="setDateField($event)" v-model="signupData.dob"  placeholder="Date of Birth" required>
                                </div>                                   
                                <div class="single-form col-lg-6">
                                    <input type="text" v-model="signupData.location"  placeholder="Location(city)" >
                                </div>                                                              
                                <div class="single-select2 col-lg-6">
                                    <select class="nice-select "  name="employment_status" @change="selectInput($event)">
                                        <option value="">--Select Employment Status--</option>
                                        <option value="Employed">Employed</option>
                                        <option value="Unemployed">Unemployed</option>
                                        <option value="Self Employed">Self Employed</option>
                                    </select>
                                </div>                                  
                                <div class="single-select2 col-lg-12">
                                    <select class="nice-select "  name="qualification" @change="selectInput($event)">
                                        <option value="">--Select Educational Qualification--</option>
                                        <option value="SSCE">SSCE</option>
                                        <option value="OND">OND</option>
                                        <option value="NCE">NCE</option>
                                        <option value="HND">HND</option>
                                        <option value="B.Sc">B.Sc</option>
                                        <option value="M.Sc">M.Sc</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </div>  

                                <div class="single-select2 col-lg-6">
                                    <label>Trade you are interested to learn</label>
                                    <select class="nice-select " name="trade_of_interest" @change="selectInput($event)" v-if="details.type.toLowerCase() == 'course'">
                                        <option value="">--Select one--</option>
                                        <option value="Carpentry">Carpentry & Furniture Manking</option>
                                        <option value="Plumbing">Plumbing & Pipe Fittings</option>
                                        <option value="Tiling">Tiling</option>
                                        <option value="Masonry">Masonry</option>
                                        <option value="Painting"> Painting </option>
                                        <option value="Interior Design"> Interior Design</option>
                                        <option value="Facility Maintenance"> Facility Management & Maintenance </option>
                                        <option value="Electrical Installation">Electrical Installation</option>
                                        <option value="CCTV Installation">CCTV Installation</option>
                                        <option value="Welding and fabrication"> Welding & fabrication</option>
                                        <option value="Building Construction & Supervision"> Building Construction & Supervision</option>
                                        <option value="Solar System and Installation"> Solar System & Inverter Installation</option>
                                        <option value="HVAC"> HVAC - Heat Ventilation and Air Conditioning</option>
                                    </select>
                                    <select class="nice-select " name="trade_of_interest" @change="selectInput($event)" v-else>
                                        <option value="">--Select one--</option>
                                        <option value="Door Making & Production">Door Making & Production</option>
                                        <option value="Door Installation">Door Installation</option>
                                        <option value="Edge Binding for Doors"> Edge Binding for Doors </option>
                                    </select>
                                </div> 
                                <div class="single-select2 col-lg-6" v-if="details.type.toLowerCase() == 'course'">
                                    <label>Select a Program</label>
                                    <select class="nice-select " name="program" @change="selectInput($event)">
                                        <option value="">--Select one--</option>
                                        <option value="1 Month Intensive">1 Month Intensive</option>
                                        <option value="3 Months Standard">3 Months Standard</option>
                                    </select>
                                </div> 

                                <div class="single-form col-lg-12">
                                    <textarea  v-model="signupData.reason_for_interest" placeholder="In few sentences, tell us why you are interested in learning the trade" ></textarea>
                                </div>  

                                <div class="single-select2 col-lg-12">
                                    <label>How did you know about this program?</label>
                                    <select class="nice-select " name="about_us" @change="selectInput($event)">
                                        <option value="" selected>--Select one--</option>
                                        <option value="Facebook">Facebook</option>
                                        <option value="Instagram">Instagram</option>
                                        <option value="LinkedIn">LinkedIn</option>
                                        <option value="Google Search">Google Search</option>
                                        <option value="Whatsapp">Whatsapp</option>
                                        <option value="Publication">Publication</option>
                                        <option value="Friend">Friend</option>
                                    </select>
                                </div> 
                                <div class="single-form">
                                    <button 
                                        type="button" 
                                        class="btn btn-primary btn-hover-dark w-100"
                                        @click="signup"
                                    >
                                        <span v-if="loading">Please wait...</span>
                                        <span v-else>Submit</span> 
                                    </button>
                                </div>                                                                                                                                                          
                            </div>
                        </form> 
                        <form v-else-if="
                            details.type && details.type.toLowerCase() == 'solar' || 
                            details.type && details.type.toLowerCase() == 'cctv' || 
                            details.type && details.type.toLowerCase() == 'pm' || 
                            details.type && details.type.toLowerCase() == 'wf' || 
                            details.type && details.type.toLowerCase() == 'painting' ||
                            details.type && details.type.toLowerCase() == 'fm' ||
                            details.type && details.type.toLowerCase() == 'sss' ||
                            details.type && details.type.toLowerCase() == 'promo' ||
                            details.type && details.type.toLowerCase() == 'specialpromo' ||
                            details.type && details.type.toLowerCase() == 'hvac'">
                            <div class="row">
                                <div class="single-form col-lg-6">
                                    <input type="text" v-model="signupData.name"  placeholder="Full Name" required>
                                </div>                                
                                <div class="single-form col-lg-6">
                                    <input type="email" v-model="signupData.email"  placeholder="Email" required>
                                </div>                                
                                <div class="single-form col-lg-6">
                                    <input type="tel" v-model="signupData.phone_number"  placeholder="Phone Number" required @change="confirmPhone">
                                </div>                                
                                <div class="single-form col-lg-6">
                                    <input type="tel" v-model="signupData.alt_phone_number"  placeholder="Whatsapp Number" required @change="confirmPhone">
                                </div>                                
                                <div class="single-form col-lg-6">
                                    <input type="number" min="16" v-model="signupData.age"  placeholder="Age" required>
                                </div>                                
                                <div class="single-select2 col-lg-6">
                                    <select class="nice-select "  name="gender" @change="selectInput($event)">
                                        <option value="">--Select Gender--</option>
                                        <option value="Female">Female</option>
                                        <option value="Male">Male</option>
                                    </select>
                                </div>  
                                <div class="single-form col-lg-12">
                                    <input type="text" @focus="setDateField($event)" v-model="signupData.dob"  placeholder="Date of Birth" required>
                                </div>                                   
                                <div class="single-form col-lg-6">
                                    <input type="text" v-model="signupData.location"  placeholder="Location(city)" >
                                </div>                                                              
                                <div class="single-select2 col-lg-6">
                                    <select class="nice-select "  name="employment_status" @change="selectInput($event)">
                                        <option value="">--Select Employment Status--</option>
                                        <option value="Employed">Employed</option>
                                        <option value="Unemployed">Unemployed</option>
                                        <option value="Self Employed">Self Employed</option>
                                    </select>
                                </div>                                  
                                <div class="single-select2 col-lg-12">
                                    <select class="nice-select "  name="qualification" @change="selectInput($event)">
                                        <option value="">--Select Educational Qualification--</option>
                                        <option value="SSCE">SSCE</option>
                                        <option value="OND">OND</option>
                                        <option value="NCE">NCE</option>
                                        <option value="HND">HND</option>
                                        <option value="B.Sc">B.Sc</option>
                                        <option value="M.Sc">M.Sc</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </div>  

                                <div class="single-select2 col-lg-6" v-if="details.type.toLowerCase() == 'specialpromo' || details.type.toLowerCase() == 'sss'">
                                    <label>Trade you are interested to learn</label>
                                    <select class="nice-select " name="trade_of_interest" @change="selectInput($event)">
                                        <option value="">--Select one--</option>
                                        <option value="Carpentry">Carpentry & Furniture Manking</option>
                                        <option value="Plumbing">Plumbing & Pipe Fittings</option>
                                        <option value="Tiling">Tiling</option>
                                        <option value="Masonry">Masonry</option>
                                        <option value="Painting"> Painting </option>
                                        <option value="Interior Design"> Interior Design</option>
                                        <option value="Facility Maintenance"> Facility Management & Maintenance </option>
                                        <option value="Electrical Installation">Electrical Installation</option>
                                        <option value="CCTV Installation">CCTV Installation</option>
                                        <option value="Welding and fabrication"> Welding & fabrication</option>
                                        <option value="Building Construction & Supervision"> Building Construction & Supervision</option>
                                        <option value="Solar System and Installation"> Solar System & Inverter Installation</option>
                                        <option value="HVAC"> HVAC - Heat Ventilation and Air Conditioning</option>
                                    </select>
                                </div> 
                                <div class="single-select2 col-lg-6" v-if="details.type.toLowerCase() == 'course'">
                                    <label>Select a Program</label>
                                    <select class="nice-select " name="program" @change="selectInput($event)">
                                        <option value="">--Select one--</option>
                                        <option value="1 Month Intensive">1 Month Intensive</option>
                                        <option value="3 Months Standard">3 Months Standard</option>
                                    </select>
                                </div> 

                                <div class="single-form col-lg-12">
                                    <textarea  v-model="signupData.reason_for_interest" placeholder="In few sentences, tell us why you are interested in learning the trade" ></textarea>
                                </div>  

                                <div class="single-select2 col-lg-12">
                                    <label>How did you know about this program?</label>
                                    <select class="nice-select " name="about_us" @change="selectInput($event)">
                                        <option value="" selected>--Select one--</option>
                                        <option value="Facebook">Facebook</option>
                                        <option value="Instagram">Instagram</option>
                                        <option value="LinkedIn">LinkedIn</option>
                                        <option value="Google Search">Google Search</option>
                                        <option value="Whatsapp">Whatsapp</option>
                                        <option value="Publication">Publication</option>
                                        <option value="Friend">Friend</option>
                                    </select>
                                </div> 
                                <div class="single-form">
                                    <button 
                                        type="button" 
                                        class="btn btn-primary btn-hover-dark w-100"
                                        @click="signup"
                                    >
                                        <span v-if="loading">Please wait...</span>
                                        <span v-else>Submit</span> 
                                    </button>
                                </div>                                                                                                                                                          
                            </div>
                        </form> 
                        <form v-else>
                            <div class="row">
                                <div class="single-form col-lg-6">
                                    <input type="text" v-model="signupData.name"  placeholder="Full Name" required>
                                </div>                                
                                <div class="single-form col-lg-6">
                                    <input type="email" v-model="signupData.email"  placeholder="Email" required>
                                </div>                                
                                <div class="single-form col-lg-6">
                                    <input type="tel" v-model="signupData.phone_number"  placeholder="Phone Number" required @change="confirmPhone">
                                </div>                                
                                <div class="single-form col-lg-6">
                                    <input type="tel" v-model="signupData.alt_phone_number"  placeholder="Whatsapp Number" required @change="confirmPhone">
                                </div>                                
                                <div class="single-form col-lg-6">
                                    <input type="number" min="16" v-model="signupData.age"  placeholder="Age" required>
                                </div>                                
                                <div class="single-select2 col-lg-6">
                                    <select class="nice-select "  name="gender" @change="selectInput($event)">
                                        <option value="">--Select Gender--</option>
                                        <option value="Female">Female</option>
                                        <option value="Male">Male</option>
                                    </select>
                                </div>  
                                <div class="single-form col-lg-12">
                                    <input type="text" @focus="setDateField($event)" v-model="signupData.dob"  placeholder="Date of Birth" required>
                                </div>                                   
                                <div class="single-form col-lg-6">
                                    <input type="text" v-model="signupData.location"  placeholder="Location(city)" >
                                </div>                                                              
                                <div class="single-select2 col-lg-6">
                                    <select class="nice-select "  name="employment_status" @change="selectInput($event)">
                                        <option value="">--Select Employment Status--</option>
                                        <option value="Employed">Employed</option>
                                        <option value="Unemployed">Unemployed</option>
                                        <option value="Self Employed">Self Employed</option>
                                    </select>
                                </div>                                  
                                <div class="single-select2 col-lg-12">
                                    <select class="nice-select "  name="qualification" @change="selectInput($event)">
                                        <option value="">--Select Educational Qualification--</option>
                                        <option value="SSCE">SSCE</option>
                                        <option value="OND">OND</option>
                                        <option value="NCE">NCE</option>
                                        <option value="HND">HND</option>
                                        <option value="B.Sc">B.Sc</option>
                                        <option value="M.Sc">M.Sc</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </div>  

                                <div class="single-select2 col-lg-6">
                                    <label>Trade you are interested to learn</label>
                                    <select class="nice-select " name="trade_of_interest" @change="selectInput($event)">
                                        <option value="">--Select one--</option>
                                        <option value="Carpentry">Carpentry</option>
                                        <option value="Plumbing">Plumbing</option>
                                        <option value="Tiling">Tiling</option>
                                        <option value="Masonry">Masonry</option>
                                        <option value="Electrical Installation">Electrical Installation</option>
                                        <option value="Interior Design"> Interior Design</option>
                                        <option value="Welding and fabrication"> Welding and fabrication</option>
                                        <option value="Facility Maintenance"> Facility Maintenance </option>
                                        <option value="Solar Design and Installation"> Solar Design and Installation</option>
                                        <option value="Painting"> Painting </option>
                                        <option value="HVAC"> HVAC - Heat Ventilation and Air Conditioning</option>
                                        <option value="MEP"> Mechanical Electrical and Plumbing (MEP)</option>
                                    </select>
                                </div> 
                                <div class="single-select2 col-lg-6">
                                    <label>Select a Program</label>
                                    <select class="nice-select " name="program" @change="selectInput($event)">
                                        <option value="">--Select one--</option>
                                        <option value="1 Month Intensive">1 Month Intensive</option>
                                        <option value="3 Months Standard">3 Months Standard</option>
                                    </select>
                                </div> 
                              
                                <div class="single-select2 col-lg-12">
                                    <label>Are you looking to get a long term employment?</label>
                                    <select class="nice-select " name="long_term_employment_interest" @change="selectInput($event)">
                                        <option value="" selected>--Select one--</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>                                                                                                                                                                 

                                <div class="single-select2 col-lg-12">
                                    <label>How did you know about this program?</label>
                                    <select class="nice-select " name="about_us" @change="selectInput($event)">
                                        <option value="" selected>--Select one--</option>
                                        <option value="Facebook">Facebook</option>
                                        <option value="Instagram">Instagram</option>
                                        <option value="LinkedIn">LinkedIn</option>
                                        <option value="Google Search">Google Search</option>
                                        <option value="Whatsapp">Whatsapp</option>
                                        <option value="Publication">Publication</option>
                                        <option value="Friend">Friend</option>
                                    </select>
                                </div> 
                                <div class="single-form">
                                    <button 
                                        type="button" 
                                        class="btn btn-primary btn-hover-dark w-100"
                                        @click="signup"
                                    >
                                        <span v-if="loading">Please wait...</span>
                                        <span v-else>Submit</span> 
                                    </button>
                                </div>                                                                                                                                                          
                            </div>
                        </form>  
                        <!-- end Core Course  -->
                      </div>
                    </div>
                </div>
                <!-- Blog Wrapper End -->
            </div>
        </div>        
    <Footer/>
</template>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue'
import Banner from '@/components/Banner.vue'
import SingleEvent from '@/components/Events/SingleEvent.vue';
import axios from 'axios';
import Swal from 'sweetalert2';

export default{
    name:"EventShow",
    components:{
        Header,Banner,Footer,SingleEvent
    },
    data() {
        return {
            details: {
                title: '',
                body: ''
            },
            signupData: {},
            loading: false, 
            localGovernments: []
        }
    },
    created() {
        this.getEvent(this.$route.params.type)
    },
    methods: {
        selectInput(event) {
            const {name, value} = event.target
            this.signupData[name] = value
            if(name == 'state') {
                this.toggleLGA()
            }
        },
     confirmPhone() {
        if(this.signupData.phone_number !== this.signupData.alt_phone_number) {
            Swal.fire({
                icon: 'error',
                toast: true,
                text: 'Phone number did not match'
            })
        }
     },
     setDateField(event) {
        // console.log(event)
        event.target.type = 'date'
     },
     getEvent(type) {
        axios.get('https://api.bhubcollege.com/api/v1/events/show/'+type)
        .then(response => {
            // console.log(response)
            if(response.data.status) {
                this.details = response.data.data
            }
        })
        .catch(err => console.log(err))
     },
     signup() {
         this.loading = true
         this.signupData.event_id = this.details.id
        //  this.signupData.event_id = this.$route.params.id
         axios.post('https://api.bhubcollege.com/api/v1/events/registration/create', this.signupData)
         .then(response => {
            //  console.log(response)
            if(response.data.status) {
                // fbq('track', 'Signup');
                Swal.fire({
                    title: 'Success',
                    icon: 'success',
                    text: response.data.message
                })
                .then(r => {
                    this.signupData = {}
                })
            }
         })
         .catch(err => {
            if(err.response && err.response.data) {
                if(err.response.status == 422 || err.response.status == 404) {
                    Swal.fire({
                        title: 'Error',
                        text: err.response.data.message,
                        icon: 'error'
                    })
                } 
            } 
         })
         .finally(() => this.loading = false)
     },
     toggleLGA() {
        let lgas = [
    {
        "state": "Abia",
        "lgas": [
        "Aba North",
        "Aba South",
        "Arochukwu",
        "Bende",
        "Ikawuno",
        "Ikwuano",
        "Isiala-Ngwa North",
        "Isiala-Ngwa South",
        "Isuikwuato",
        "Umu Nneochi",
        "Obi Ngwa",
        "Obioma Ngwa",
        "Ohafia",
        "Ohaozara",
        "Osisioma",
        "Ugwunagbo",
        "Ukwa West",
        "Ukwa East",
        "Umuahia North",
        "Umuahia South"
        ]
    },
    {
        "state": "Adamawa",
        "lgas": [
        "Demsa",
        "Fufore",
        "Ganye",
        "Girei",
        "Gombi",
        "Guyuk",
        "Hong",
        "Jada",
        "Lamurde",
        "Madagali",
        "Maiha",
        "Mayo-Belwa",
        "Michika",
        "Mubi-North",
        "Mubi-South",
        "Numan",
        "Shelleng",
        "Song",
        "Toungo",
        "Yola North",
        "Yola South"
        ]
    },
    {
        "state": "Akwa Ibom",
        "lgas": [
        "Abak",
        "Eastern-Obolo",
        "Eket",
        "Esit-Eket",
        "Essien-Udim",
        "Etim-Ekpo",
        "Etinan",
        "Ibeno",
        "Ibesikpo-Asutan",
        "Ibiono-Ibom",
        "Ika",
        "Ikono",
        "Ikot-Abasi",
        "Ikot-Ekpene",
        "Ini",
        "Itu",
        "Mbo",
        "Mkpat-Enin",
        "Nsit-Atai",
        "Nsit-Ibom",
        "Nsit-Ubium",
        "Obot-Akara",
        "Okobo",
        "Onna",
        "Oron",
        "Oruk Anam",
        "Udung-Uko",
        "Ukanafun",
        "Urue-Offong/Oruko",
        "Uruan",
        "Uyo"
        ]
    },
    {
        "state": "Anambra",
        "lgas": [
        "Aguata",
        "Anambra East",
        "Anambra West",
        "Anaocha",
        "Awka North",
        "Awka South",
        "Ayamelum",
        "Dunukofia",
        "Ekwusigo",
        "Idemili-North",
        "Idemili-South",
        "Ihiala",
        "Njikoka",
        "Nnewi-North",
        "Nnewi-South",
        "Ogbaru",
        "Onitsha-North",
        "Onitsha-South",
        "Orumba-North",
        "Orumba-South"
        ]
    },
    {
        "state": "Bauchi",
        "lgas": [
        "Alkaleri",
        "Bauchi",
        "Bogoro",
        "Damban",
        "Darazo",
        "Dass",
        "Gamawa",
        "Ganjuwa",
        "Giade",
        "Itas/Gadau",
        "Jama'Are",
        "Katagum",
        "Kirfi",
        "Misau",
        "Ningi",
        "Shira",
        "Tafawa-Balewa",
        "Toro",
        "Warji",
        "Zaki"
        ]
    },
    {
        "state": "Benue",
        "lgas": [
        "Ado",
        "Agatu",
        "Apa",
        "Buruku",
        "Gboko",
        "Guma",
        "Gwer-East",
        "Gwer-West",
        "Katsina-Ala",
        "Konshisha",
        "Kwande",
        "Logo",
        "Makurdi",
        "Ogbadibo",
        "Ohimini",
        "Oju",
        "Okpokwu",
        "Otukpo",
        "Tarka",
        "Ukum",
        "Ushongo",
        "Vandeikya"
        ]
    },
    {
        "state": "Borno",
        "lgas": [
        "Abadam",
        "Askira-Uba",
        "Bama",
        "Bayo",
        "Biu",
        "Chibok",
        "Damboa",
        "Dikwa",
        "Gubio",
        "Guzamala",
        "Gwoza",
        "Hawul",
        "Jere",
        "Kaga",
        "Kala/Balge",
        "Konduga",
        "Kukawa",
        "Kwaya-Kusar",
        "Mafa",
        "Magumeri",
        "Maiduguri",
        "Marte",
        "Mobbar",
        "Monguno",
        "Ngala",
        "Nganzai",
        "Shani"
        ]
    },
    {
        "state": "Bayelsa",
        "lgas": [
        "Brass",
        "Ekeremor",
        "Kolokuma/Opokuma",
        "Nembe",
        "Ogbia",
        "Sagbama",
        "Southern-Ijaw",
        "Yenagoa"
        ]
    },
    {
        "state": "Cross River",
        "lgas": [
        "Abi",
        "Akamkpa",
        "Akpabuyo",
        "Bakassi",
        "Bekwarra",
        "Biase",
        "Boki",
        "Calabar-Municipal",
        "Calabar-South",
        "Etung",
        "Ikom",
        "Obanliku",
        "Obubra",
        "Obudu",
        "Odukpani",
        "Ogoja",
        "Yakurr",
        "Yala"
        ]
    },
    {
        "state": "Delta",
        "lgas": [
        "Aniocha North",
        "Aniocha-North",
        "Aniocha-South",
        "Bomadi",
        "Burutu",
        "Ethiope-East",
        "Ethiope-West",
        "Ika-North-East",
        "Ika-South",
        "Isoko-North",
        "Isoko-South",
        "Ndokwa-East",
        "Ndokwa-West",
        "Okpe",
        "Oshimili-North",
        "Oshimili-South",
        "Patani",
        "Sapele",
        "Udu",
        "Ughelli-North",
        "Ughelli-South",
        "Ukwuani",
        "Uvwie",
        "Warri South-West",
        "Warri North",
        "Warri South"
        ]
    },
    {
        "state": "Ebonyi",
        "lgas": [
        "Abakaliki",
        "Afikpo-North",
        "Afikpo South (Edda)",
        "Ebonyi",
        "Ezza-North",
        "Ezza-South",
        "Ikwo",
        "Ishielu",
        "Ivo",
        "Izzi",
        "Ohaukwu",
        "Onicha"
        ]
    },
    {
        "state": "Edo",
        "lgas": [
        "Akoko Edo",
        "Egor",
        "Esan-Central",
        "Esan-North-East",
        "Esan-South-East",
        "Esan-West",
        "Etsako-Central",
        "Etsako-East",
        "Etsako-West",
        "Igueben",
        "Ikpoba-Okha",
        "Oredo",
        "Orhionmwon",
        "Ovia-North-East",
        "Ovia-South-West",
        "Owan East",
        "Owan-West",
        "Uhunmwonde"
        ]
    },
    {
        "state": "Ekiti",
        "lgas": [
        "Ado-Ekiti",
        "Efon",
        "Ekiti-East",
        "Ekiti-South-West",
        "Ekiti-West",
        "Emure",
        "Gbonyin",
        "Ido-Osi",
        "Ijero",
        "Ikere",
        "Ikole",
        "Ilejemeje",
        "Irepodun/Ifelodun",
        "Ise-Orun",
        "Moba",
        "Oye"
        ]
    },
    {
        "state": "Enugu",
        "lgas": [
        "Aninri",
        "Awgu",
        "Enugu-East",
        "Enugu-North",
        "Enugu-South",
        "Ezeagu",
        "Igbo-Etiti",
        "Igbo-Eze-North",
        "Igbo-Eze-South",
        "Isi-Uzo",
        "Nkanu-East",
        "Nkanu-West",
        "Nsukka",
        "Oji-River",
        "Udenu",
        "Udi",
        "Uzo-Uwani"
        ]
    },
    {
        "state": "Federal Capital Territory",
        "lgas": [
        "Abuja",
        "Kwali",
        "Kuje",
        "Gwagwalada",
        "Bwari",
        "Abaji"
        ]
    },
    {
        "state": "Gombe",
        "lgas": [
        "Akko",
        "Balanga",
        "Billiri",
        "Dukku",
        "Funakaye",
        "Gombe",
        "Kaltungo",
        "Kwami",
        "Nafada",
        "Shongom",
        "Yamaltu/Deba"
        ]
    },
    {
        "state": "Imo",
        "lgas": [
        "Aboh-Mbaise",
        "Ahiazu-Mbaise",
        "Ehime-Mbano",
        "Ezinihitte",
        "Ideato-North",
        "Ideato-South",
        "Ihitte/Uboma",
        "Ikeduru",
        "Isiala-Mbano",
        "Isu",
        "Mbaitoli",
        "Ngor-Okpala",
        "Njaba",
        "Nkwerre",
        "Nwangele",
        "Obowo",
        "Oguta",
        "Ohaji-Egbema",
        "Okigwe",
        "Onuimo",
        "Orlu",
        "Orsu",
        "Oru-East",
        "Oru-West",
        "Owerri-Municipal",
        "Owerri-North",
        "Owerri-West"
        ]
    },
    {
        "state": "Jigawa",
        "lgas": [
        "Auyo",
        "Babura",
        "Biriniwa",
        "Birnin-Kudu",
        "Buji",
        "Dutse",
        "Gagarawa",
        "Garki",
        "Gumel",
        "Guri",
        "Gwaram",
        "Gwiwa",
        "Hadejia",
        "Jahun",
        "Kafin-Hausa",
        "Kaugama",
        "Kazaure",
        "Kiri kasama",
        "Maigatari",
        "Malam Madori",
        "Miga",
        "Ringim",
        "Roni",
        "Sule-Tankarkar",
        "Taura",
        "Yankwashi"
        ]
    },
    {
        "state": "Kebbi",
        "lgas": [
        "Aleiro",
        "Arewa-Dandi",
        "Argungu",
        "Augie",
        "Bagudo",
        "Birnin-Kebbi",
        "Bunza",
        "Dandi",
        "Fakai",
        "Gwandu",
        "Jega",
        "Kalgo",
        "Koko-Besse",
        "Maiyama",
        "Ngaski",
        "Sakaba",
        "Shanga",
        "Suru",
        "Wasagu/Danko",
        "Yauri",
        "Zuru"
        ]
    },
    {
        "state": "Kaduna",
        "lgas": [
        "Birnin-Gwari",
        "Chikun",
        "Giwa",
        "Igabi",
        "Ikara",
        "Jaba",
        "Jema'A",
        "Kachia",
        "Kaduna-North",
        "Kaduna-South",
        "Kagarko",
        "Kajuru",
        "Kaura",
        "Kauru",
        "Kubau",
        "Kudan",
        "Lere",
        "Makarfi",
        "Sabon-Gari",
        "Sanga",
        "Soba",
        "Zangon-Kataf",
        "Zaria"
        ]
    },
    {
        "state": "Kano",
        "lgas": [
        "Ajingi",
        "Albasu",
        "Bagwai",
        "Bebeji",
        "Bichi",
        "Bunkure",
        "Dala",
        "Dambatta",
        "Dawakin-Kudu",
        "Dawakin-Tofa",
        "Doguwa",
        "Fagge",
        "Gabasawa",
        "Garko",
        "Garun-Mallam",
        "Gaya",
        "Gezawa",
        "Gwale",
        "Gwarzo",
        "Kabo",
        "Kano-Municipal",
        "Karaye",
        "Kibiya",
        "Kiru",
        "Kumbotso",
        "Kunchi",
        "Kura",
        "Madobi",
        "Makoda",
        "Minjibir",
        "Nasarawa",
        "Rano",
        "Rimin-Gado",
        "Rogo",
        "Shanono",
        "Sumaila",
        "Takai",
        "Tarauni",
        "Tofa",
        "Tsanyawa",
        "Tudun-Wada",
        "Ungogo",
        "Warawa",
        "Wudil"
        ]
    },
    {
        "state": "Kogi",
        "lgas": [
        "Adavi",
        "Ajaokuta",
        "Ankpa",
        "Dekina",
        "Ibaji",
        "Idah",
        "Igalamela-Odolu",
        "Ijumu",
        "Kabba/Bunu",
        "Kogi",
        "Lokoja",
        "Mopa-Muro",
        "Ofu",
        "Ogori/Magongo",
        "Okehi",
        "Okene",
        "Olamaboro",
        "Omala",
        "Oyi",
        "Yagba-East",
        "Yagba-West"
        ]
    },
    {
        "state": "Katsina",
        "lgas": [
        "Bakori",
        "Batagarawa",
        "Batsari",
        "Baure",
        "Bindawa",
        "Charanchi",
        "Dan-Musa",
        "Dandume",
        "Danja",
        "Daura",
        "Dutsi",
        "Dutsin-Ma",
        "Faskari",
        "Funtua",
        "Ingawa",
        "Jibia",
        "Kafur",
        "Kaita",
        "Kankara",
        "Kankia",
        "Katsina",
        "Kurfi",
        "Kusada",
        "Mai-Adua",
        "Malumfashi",
        "Mani",
        "Mashi",
        "Matazu",
        "Musawa",
        "Rimi",
        "Sabuwa",
        "Safana",
        "Sandamu",
        "Zango"
        ]
    },
    {
        "state": "Kwara",
        "lgas": [
        "Asa",
        "Baruten",
        "Edu",
        "Ekiti (Araromi/Opin)",
        "Ilorin-East",
        "Ilorin-South",
        "Ilorin-West",
        "Isin",
        "Kaiama",
        "Moro",
        "Offa",
        "Oke-Ero",
        "Oyun",
        "Pategi"
        ]
    },
    {
        "state": "Lagos",
        "lgas": [
        "Agege",
        "Ajeromi-Ifelodun",
        "Alimosho",
        "Amuwo-Odofin",
        "Apapa",
        "Badagry",
        "Epe",
        "Eti-Osa",
        "Ibeju-Lekki",
        "Ifako-Ijaiye",
        "Ikeja",
        "Ikorodu",
        "Kosofe",
        "Lagos-Island",
        "Lagos-Mainland",
        "Mushin",
        "Ojo",
        "Oshodi-Isolo",
        "Shomolu",
        "Surulere",
        "Yewa-South"
        ]
    },
    {
        "state": "Nasarawa",
        "lgas": [
        "Akwanga",
        "Awe",
        "Doma",
        "Karu",
        "Keana",
        "Keffi",
        "Kokona",
        "Lafia",
        "Nasarawa",
        "Nasarawa-Eggon",
        "Obi",
        "Wamba",
        "Toto"
        ]
    },
    {
        "state": "Niger",
        "lgas": [
        "Agaie",
        "Agwara",
        "Bida",
        "Borgu",
        "Bosso",
        "Chanchaga",
        "Edati",
        "Gbako",
        "Gurara",
        "Katcha",
        "Kontagora",
        "Lapai",
        "Lavun",
        "Magama",
        "Mariga",
        "Mashegu",
        "Mokwa",
        "Moya",
        "Paikoro",
        "Rafi",
        "Rijau",
        "Shiroro",
        "Suleja",
        "Tafa",
        "Wushishi"
        ]
    },
    {
        "state": "Ogun",
        "lgas": [
        "Abeokuta-North",
        "Abeokuta-South",
        "Ado-Odo/Ota",
        "Ewekoro",
        "Ifo",
        "Ijebu-East",
        "Ijebu-North",
        "Ijebu-North-East",
        "Ijebu-Ode",
        "Ikenne",
        "Imeko-Afon",
        "Ipokia",
        "Obafemi-Owode",
        "Odeda",
        "Odogbolu",
        "Ogun-Waterside",
        "Remo-North",
        "Shagamu",
        "Yewa North"
        ]
    },
    {
        "state": "Ondo",
        "lgas": [
        "Akoko North-East",
        "Akoko North-West",
        "Akoko South-West",
        "Akoko South-East",
        "Akure-North",
        "Akure-South",
        "Ese-Odo",
        "Idanre",
        "Ifedore",
        "Ilaje",
        "Ile-Oluji-Okeigbo",
        "Irele",
        "Odigbo",
        "Okitipupa",
        "Ondo West",
        "Ondo-East",
        "Ose",
        "Owo"
        ]
    },
    {
        "state": "Osun",
        "lgas": [
        "Atakumosa West",
        "Atakumosa East",
        "Ayedaade",
        "Ayedire",
        "Boluwaduro",
        "Boripe",
        "Ede South",
        "Ede North",
        "Egbedore",
        "Ejigbo",
        "Ife North",
        "Ife South",
        "Ife-Central",
        "Ife-East",
        "Ifelodun",
        "Ila",
        "Ilesa-East",
        "Ilesa-West",
        "Irepodun",
        "Irewole",
        "Isokan",
        "Iwo",
        "Obokun",
        "Odo-Otin",
        "Ola Oluwa",
        "Olorunda",
        "Oriade",
        "Orolu",
        "Osogbo"
        ]
    },
    {
        "state": "Oyo",
        "lgas": [
        "Afijio",
        "Akinyele",
        "Atiba",
        "Atisbo",
        "Egbeda",
        "Ibadan North",
        "Ibadan North-East",
        "Ibadan North-West",
        "Ibadan South-East",
        "Ibadan South-West",
        "Ibarapa-Central",
        "Ibarapa-East",
        "Ibarapa-North",
        "Ido",
        "Ifedayo",
        "Irepo",
        "Iseyin",
        "Itesiwaju",
        "Iwajowa",
        "Kajola",
        "Lagelu",
        "Ogo-Oluwa",
        "Ogbomosho-North",
        "Ogbomosho-South",
        "Olorunsogo",
        "Oluyole",
        "Ona-Ara",
        "Orelope",
        "Ori-Ire",
        "Oyo-West",
        "Oyo-East",
        "Saki-East",
        "Saki-West",
        "Surulere"
        ]
    },
    {
        "state": "Plateau",
        "lgas": [
        "Barkin-Ladi",
        "Bassa",
        "Bokkos",
        "Jos-East",
        "Jos-North",
        "Jos-South",
        "Kanam",
        "Kanke",
        "Langtang-North",
        "Langtang-South",
        "Mangu",
        "Mikang",
        "Pankshin",
        "Qua'an Pan",
        "Riyom",
        "Shendam",
        "Wase"
        ]
    },
    {
        "state": "Rivers",
        "lgas": [
        "Abua/Odual",
        "Ahoada-East",
        "Ahoada-West",
        "Akuku Toru",
        "Andoni",
        "Asari-Toru",
        "Bonny",
        "Degema",
        "Eleme",
        "Emuoha",
        "Etche",
        "Gokana",
        "Ikwerre",
        "Khana",
        "Obio/Akpor",
        "Ogba-Egbema-Ndoni",
        "Ogba/Egbema/Ndoni",
        "Ogu/Bolo",
        "Okrika",
        "Omuma",
        "Opobo/Nkoro",
        "Oyigbo",
        "Port-Harcourt",
        "Tai"
        ]
    },
    {
        "state": "Sokoto",
        "lgas": [
        "Binji",
        "Bodinga",
        "Dange-Shuni",
        "Gada",
        "Goronyo",
        "Gudu",
        "Gwadabawa",
        "Illela",
        "Kebbe",
        "Kware",
        "Rabah",
        "Sabon Birni",
        "Shagari",
        "Silame",
        "Sokoto-North",
        "Sokoto-South",
        "Tambuwal",
        "Tangaza",
        "Tureta",
        "Wamako",
        "Wurno",
        "Yabo"
        ]
    },
    {
        "state": "Taraba",
        "lgas": [
        "Ardo-Kola",
        "Bali",
        "Donga",
        "Gashaka",
        "Gassol",
        "Ibi",
        "Jalingo",
        "Karim-Lamido",
        "Kurmi",
        "Lau",
        "Sardauna",
        "Takum",
        "Ussa",
        "Wukari",
        "Yorro",
        "Zing"
        ]
    },
    {
        "state": "Yobe",
        "lgas": [
        "Bade",
        "Bursari",
        "Damaturu",
        "Fika",
        "Fune",
        "Geidam",
        "Gujba",
        "Gulani",
        "Jakusko",
        "Karasuwa",
        "Machina",
        "Nangere",
        "Nguru",
        "Potiskum",
        "Tarmuwa",
        "Yunusari",
        "Yusufari"
        ]
    },
    {
        "state": "Zamfara",
        "lgas": [
        "Anka",
        "Bakura",
        "Birnin Magaji/Kiyaw",
        "Bukkuyum",
        "Bungudu",
        "Gummi",
        "Gusau",
        "Isa",
        "Kaura-Namoda",
        "Kiyawa",
        "Maradun",
        "Maru",
        "Shinkafi",
        "Talata-Mafara",
        "Tsafe",
        "Zurmi"
        ]
    }
            ]

        let data =  lgas.filter(state => state.state === this.signupData.state)[0].lgas
        this.localGovernments = data
        // console.log(this.signupData)
        // console.log(data)
     },
    }
}
</script>